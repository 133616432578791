<template>
  <div>
    <!--begin::Documents-->
    <div class="card card-custom gutter-b">
      <div class="card-header pr-9 pl-10">
        <div class="card-title">
          <h3 v-if="edit_form" class="card-label">Document Edit</h3>
          <h3 v-else class="card-label">Document Add</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <button
                type="button"
                class="ma-2 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span class="v-btn__content" @click="redirectToDocumentList()">
                  Back
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <!-- <form class="form" novalidate="novalidate" id="kt_document_form"> -->
        <!--begin::Example-->
        <div class="example">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container fluid>
              <v-row align="center">
                <v-text-field
                  v-model="document.name"
                  :rules="nameRules"
                  label="Document Name"
                  dense
                  required
                  outlined
                ></v-text-field>
              </v-row>

              <v-row
                v-if="edit_form"
                class="justify-end"
                style="margin-top: 10px !important;margin-bottom: 15px !important;"
              >

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="margin-top: -12px;"
                    color="green lighten-1"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="downloadDoc(document.doc_path)" download
                  >
                    mdi-download 
                  </v-icon>
                </template>
                <span> Download Existing Document File</span>
              </v-tooltip>

                
              </v-row>

              <v-row>
                <v-file-input
                  show-size
                  label="Document file"
                  dense
                  outlined
                  ref="file"
                  :rules="!edit_form ? fileRules : []"
                  v-model="document.doc_file"
                >
                </v-file-input>
                <!-- <input type="file" class="custom-file-input" id="customFile" ref="file" @change="inputChanged()"> -->
              </v-row>

              <v-row align="center">
                <v-combobox
                  multiple
                  v-model="document.selected_tags"
                  label="Tags"
                  append-icon
                  chips
                  deletable-chips
                  outlined
                  dense
                  :items="tags"
                  class="tag-input"
                  @keyup.tab="updateTags"
                >
                </v-combobox>
              </v-row>

              <v-row align="center">
                <v-select
                  v-model="document.selected_users"
                  :items="company_user_list"
                  label="Designated Person"
                  :rules="[v => !!v || 'Designated Person Type is required']"
                  multiple
                  dense
                  outlined
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="toggle">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            document.selected_users.length > 0
                              ? 'indigo darken-4'
                              : ''
                          "
                        >
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Select All
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:append-item>
                    <v-divider class="mb-2"></v-divider>
                  </template>
                </v-select>
              </v-row>

              <v-row>
                <v-select
                  class="pr-2"
                  cols="12"
                  sm="6"
                  v-model="document.doc_type"
                  :items="document_type_list"
                  label="Document Type"
                  :rules="[v => !!v || 'Document Type is required']"
                  outlined
                  dense
                >
                </v-select>
                <v-tooltip bottom v-if="document_type_list_count == 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="margin-top: -30px;"
                      color="grey lighten-1"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                  <span> Please add Document Type from Admin Section > Document Type</span>
                </v-tooltip>
                <v-menu
                  :close-on-content-click="false"
                  cols="12"
                  lg="6"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="pl-2 date-text-input"
                      :value="computedDateFormattedMomentjs"
                      clearable
                      dense
                      outlined
                      label="Renewal Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="document.renewal_date = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="document.renewal_date"
                    @change="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-row>

              <v-row v-if="document.renewal_date">
                <v-select
                  v-model="document.days"
                  :items="remind_before_list"
                  label="Remind Before"
                  dense
                  outlined
                ></v-select>
              </v-row>
            </v-container>

            <v-container fluid class="card-footer pl-3 pt-3">
              <v-btn
                v-if="edit_form"
                :disabled="!valid || disable_btn"
                color="success"
                class="mr-4"
                ref="kt_store_submit"
                @click="validateEditForm"
              >
                <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                Update
              </v-btn>
              <v-btn
                v-else
                :disabled="!valid || disable_btn"
                color="success"
                class="mr-4"
                ref="kt_store_submit"
                @click="validate"
              >
                <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                Save
              </v-btn>
            </v-container>
          </v-form>
        </div>
        <!-- </form> -->
      </div>
    </div>
    <!--end::Documents-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import moment from "moment";
// import { format, parseISO } from "date-fns";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_TAGS,
  GET_REMINDER_DAYS,
  GET_DOCUMENT_TYPES
} from "@/core/services/store/tag.module";
import { GET_COMPANY_USERS } from "@/core/services/store/user.module";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

export default {
  name: "documents",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      document: {
        name: "",
        doc_file: [],
        selected_tags: [],
        selected_users: [],
        doc_type: "",
        // renewal_date: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
        renewal_date: "",
        days: "",
        doc_files: "",
        doc_path: ""
      },
      menu1: false, //use for date
      fileRules: [
        value =>
          !value ||
          value.size < 5000000 ||
          "File size should be less than 5 MB!"
      ],
      valid: true,
      disable_btn: false,
      nameRules: [v => !!v || "Name is required"],
      usersRules: [v => !!v || "Designated person is required"],
      remind_before: [],
      remind_before_list: [],
      tags: ["here", "there"],
      company_users: "",
      company_user_list: [],
      document_types: [],
      document_type_list: [],
      document_type_list_count: 0,
      search: "", //sync search,
      edit_form: false
    };
  },
  props: {
    doc_id: String
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters(["usersTags", "reminderDays", "documentTypes"]),
    ...mapGetters(["companyUsers"]),

    computedDateFormattedMomentjs() {
      if (this.document.renewal_date) {
        return this.document.renewal_date
          ? moment(this.document.renewal_date).format("MM-DD-YYYY")
          : "";
      } else {
        return "";
      }
    },
    icon() {
      if (this.likesAllUsers) return "mdi-close-box";
      if (this.likesSomeUser) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    config() {
      return this.layoutConfig();
    },
    likesAllUsers() {
      return (
        this.document.selected_users.length === this.company_user_list.length
      );
    },
    likesSomeUser() {
      return this.document.selected_users.length > 0 && !this.likesAllUsers;
    }
  },
  mounted() {
    let context = this;
    if (this.$route.params.id) {
      context.edit_form = true;
      axios({
        method: "get",
        url: "document/" + this.$route.params.id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.document.name = result.data.document.name;
          context.document.doc_type = result.data.document.doc_type;
          context.document.renewal_date = result.data.document.renewal_date;
          context.document.days = result.data.document.days;
          context.document.selected_tags = result.data.document.selected_tags;
          context.document.selected_users = result.data.document.selected_users;
          context.document.doc_path = result.data.document.doc_path;
        },
        function(error) {
          Swal.fire("Error", "Document not found.", "error");
          context.$router.push({ name: "documentsList" });
        }
      );
    }

    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Documents" }]);
    // this.getTags();
    this.refreshTags();
    this.getCompanyUsers();
    this.getReminderDays();
    this.getDocumentTypes();

    const document_form = KTUtil.getById("kt_document_form");

    this.fv = formValidation(document_form, {});
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    downloadDoc() {
      let url =
        process.env.VUE_APP_DOC_URL +
        "api/downloadDocument/" +
        this.$route.params.id;
      window.open(url);
    },
    updateDocument() {
      var context = this;
      var formData = new FormData();
      formData.append("doc_file", this.document.doc_file);
      formData.append("name", this.document.name);
      formData.append("tags", this.document.selected_tags);
      formData.append("users", this.document.selected_users);
      formData.append("doc_type", this.document.doc_type);
      formData.append("days", this.document.days);
      formData.append("renewal_date", this.document.renewal_date);
      //put/patch request return blank array as form data.
      axios
        .post("document/" + this.$route.params.id, formData)
        .then(() => {
          Swal.fire("Updated", "Document updated successfully", "success");
        })
        .then(() => {
          context.refreshTags();
          context.$router.go(-1);
          context.disable_btn = false;
        },
        function(error) {
          Swal.fire("Error", "Document not found.", "error");
          context.$router.push({ name: "documentsList" });
        });
    },
    redirectToDocumentList() {
      var context = this;
      context.$router.go(-1);
      // this.$router.push({ name: "documentsList" });
    },
    getDocumentTypes() {
      this.$store.dispatch(GET_DOCUMENT_TYPES).then(() => {
        this.document_types = this.documentTypes;

        this.document_types.forEach(doc_type => {
          this.document_type_list.push({
            text: doc_type.type,
            value: doc_type.type
            // value: doc_type.id
          });
          this.document_type_list_count += 1;
        });
      });
    },
    getReminderDays() {
      let context = this;
      this.$store.dispatch(GET_REMINDER_DAYS).then(() => {
        if (this.reminderDays) {
          this.remind_before = this.reminderDays;
          this.remind_before.forEach(reminder => {
            this.remind_before_list.push({
              text: reminder.no_of_days,
              value: reminder.days
            });
          });
        } else {
          context.getReminderDays();
        }
      });
    },
    getCompanyUsers() {
      let context = this;
      this.$store.dispatch(GET_COMPANY_USERS).then(() => {
        this.company_users = this.companyUsers;
        if (this.company_users) {
          this.company_users.forEach(user => {
            this.company_user_list.push(user.first_name + " " + user.last_name);
          });
        } else {
          axios({
            method: "get",
            url: "get-company-users",
            baseURL: process.env.VUE_APP_ACBACK_URL
          }).then(function(result) {
            if (result.data.company_users) {
              result.data.company_users.forEach(user => {
                context.company_user_list.push(
                  user.first_name + " " + user.last_name
                );
              });
            }
          });
        }
      });
    },
    getTags() {
      let context = this;
      this.$store.dispatch(GET_TAGS).then(() => {
        if (this.usersTags) {
          this.tags = this.usersTags;
        } else {
          context.getTags();
        }
      });
    },
    submitDocument() {
      var context = this;
      var formData = new FormData();
      formData.append("doc_file", this.document.doc_file);
      formData.append("name", this.document.name);
      formData.append("tags", this.document.selected_tags);
      formData.append("users", this.document.selected_users);
      formData.append("doc_type", this.document.doc_type);
      formData.append("days", this.document.days);
      formData.append("renewal_date", this.document.renewal_date);
      axios.post("document", formData).then(
        function(result) {
          context.refreshTags();
          context.$router.go(-1);
          Swal.fire("Success", result.data.message, "success");
          context.disable_btn = false;
        },
        function() {
          Swal.fire("Error", "Error in save document", "error");
          context.disable_btn = false;
        }
      );
    },
    refreshTags() {
      let context = this;
      axios({
        method: "post",
        url: "get-tags",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(function(result) {
        if (result.data.tags) {
          context.tags = result.data.tags;
        }
      });
    },
    updateTags() {
      this.$nextTick(() => {
        this.document.selected_tags.push(this.search);
        this.document.selected_tags.pop();
        // this.$nextTick(() => {
        //   this.search = "";
        // });
      });
    },
    validateEditForm() {
      let context = this;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        context.disable_btn = true;
        this.updateDocument();
      } else {
        this.snackbar = true;
        context.disable_btn = false;
      }
    },
    validate() {
      let context = this;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        context.disable_btn = true;
        this.submitDocument();
      } else {
        this.snackbar = true;
        context.disable_btn = false;
      }
    },
    remove(item) {
      this.document.selected_tags.splice(this.tags.indexOf(item), 1);
      this.tags = [...this.tags];
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllUsers) {
          this.document.selected_users = [];
        } else {
          this.document.selected_users = this.company_user_list.slice();
        }
      });
    }
  }
};
</script>
